export const environment = {
    production: true,
    banorte:false,
    ente: "H. Ayuntamiento de Mocorito",
    administracion: "2024 - 2027",
    url_escudo: "../assets/mocorito/escudo.png",
    url_administracion: "./assets/mocorito/logo-2021-2024-mocorito.png",
    url_footer: "../assets/mocorito/mocorito.png",
    direcciones: [
        "Hidalgo y Morelos #32, Centro Histórico",
        "C.P. 	80800 ",
        "Mocorito, Sinaloa"
    ],
    telefonos: [
        "(673) 735 0292",
        "(673) 735 0275"
    ],
    emails: [
        "Tesoreriamocorito2021@hotmail.com",
        "presidencia@mocorito.gob.mx"
    ],
    url_aviso: "http://mocorito.gob.mx/cms/informacion-historica/",
    url_webapi: "https://servidorprogramacion.ddns.net/PAGOS_SADMUN_MOCORITO/api/",
    url_sitio: "https://pagosmocorito.somee.com/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "018",
    municipio: "MOCORITO",
    firebase: {
        apiKey: "AIzaSyCOH8HpPFT2DpFIWzb7BIr0VVnnj6s-5Yw",
        authDomain: "pagosmocorito.firebaseapp.com",
        projectId: "pagosmocorito",
        storageBucket: "pagosmocorito.appspot.com",
        messagingSenderId: "561875897437",
        appId: "1:561875897437:web:78ab65b9b9852cc98891b4",
        measurementId: "G-W1299QT0JG"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de Mocorito para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "",
    llave: "",
    img_ayuda_1: '../assets/mocorito/pasos.png',
    img_ayuda_2: '../assets/mocorito/ubicarclavecatastral.png',
    img_ayuda_3: '../assets/mocorito/consultaadeudo.png'
  };
